<template>
  <div class="not-found-container">
    <div class="not-found-message">
      <b>Sayfa Bulunamadı</b>
      <b>Anasayfaya Yönlendiriliyorsunuz...</b>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {
      time: 5,
    };
  },
  created() {
    this.$router.push({ path: "/" }, () => {});
  },
};
</script>

<style lang="scss">
.not-found-container {
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  .not-found-message {
    width: 1400px;
    align-items: center;
    display: flex;
    padding-top: 3rem;
    flex-direction: column;
    text-align: center;
    color: #777777;
    &:nth-child(1) {
      font-size: 1.5rem;
    }
  }
  .counter {
    margin-top: 2rem;
    font-size: 2rem;
    font-weight: bold;
    height: 8rem;
    width: 8rem;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    border: 2px solid #e0e0e0;
    background-color: rgba(255, 228, 196, 0.349);
  }
}
</style>
